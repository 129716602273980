<template>
  <div>
    <button class="px-2 text-2xl" @click="decrease">-</button>
    <span class="px-1 text-xl">{{ num }}</span>
    <button class="px-2 text-2xl" @click="increase">+</button>
  </div>
</template>

<script>
export default {
  name: 'PlusMinus',

  props: {
    allowNegative: {
      type: Boolean,
      default: false,
    },
    numberOfItems: {
      type: Number,
      default: 0,
    },
  },

  emits: {
    increase: () => true,
    decrease: () => true,
  },

  data() {
    return {
      num: this.numberOfItems,
    }
  },

  methods: {
    increase() {
      this.num++
      this.$emit('increase')
    },

    decrease() {
      if (!this.allowNegative && this.num === 0) return
      this.num--
      this.$emit('decrease')
    },
  },
}
</script>
