<template>
  <div>
    <div v-if="price > 0">
      + {{ amountFromCents(price) }}{{ currency | currencify }}
    </div>
    <div v-if="price < 0">
      {{ amountFromCents(price) }}{{ currency | currencify }}
    </div>
  </div>
</template>

<script>
import { amountFromCents } from '../utils/utils'

export default {
  props: {
    price: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  methods: {
    amountFromCents,
  },
}
</script>
