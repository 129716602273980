<template>
  <div>
    <span class="font-bold text-lg">
      {{ $t('total-price') }}
      <span :class="{ 'line-through text-red-500': hasDiscount }">
        {{ totalCost }}
      </span>
      <span class="pl-1" v-if="hasDiscount">
        {{ totalCost - discount.discount }}
      </span>
      {{ currency | currencify }}
    </span>

    <div class="text-sm" v-if="position">
      {{ position }}
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: "TotalCost",

  props: {
    hasDiscount: {
      type: Boolean,
      required: true,
    },
    totalCost: {
      type: Number,
      required: true,
    },
    discount: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: false,
    },
  },
}
</script>
