const VIVA_WALLET = 'viva'
const STRIPE = 'stripe'
const CASH = 'cash'
const CARD = 'card'

module.exports = {
  VIVA_WALLET,
  STRIPE,
  CASH,
  CARD,
}
