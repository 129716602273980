export const settings = {
  APP_NAME: 'system',
  API_SERVER: getAPIUri(),
  API_DOMAIN: getAPIDomain(),
  BASE_URI: getBaseUri(),
  DOMAIN: 'clickorder.co',
  STRIPE: {
    pk:
      'pk_test_51JvoXQDQrpx26KU43ZB9ncYC1Yqgolqercs9ByS7C9aWy8eImJPoDXLGzkjcxujE1FrTURGh7URfcbFwM1Qb9B6n00O0gwgho7',
  },
  VIVA: {
    checkout_web: 'https://demo.vivapayments.com/web/checkout',
  },
  COMPANY: {
    name: 'ClickOrder',
    home: getBaseUri(),
    about: `${getBaseUri()}/about-us`,
    terms: `${getBaseUri()}/terms-and-conditions`,
    privacy: `${getBaseUri()}/privacy-policy`,
    contact: `${getBaseUri()}/contact-us`,
    blog: `${getBaseUri()}/blog`,
    merchant: {
      home: getMerchantUri(),
      login: `${getMerchantUri()}/auth/login`,
    },
    contact_email: 'hello@clickorder.co',
  },
}

// TODO: use config.dev.json, config.prod.json, config.local.json
function getAPIDomain() {
  if (process.env.NODE_ENV === 'production') return 'https://clickorder.co'
  if (process.env.NODE_ENV !== 'production') return 'http://localhost:8081'
}

function getAPIUri() {
  return `${getAPIDomain()}/api/v1`
}

function getBaseUri() {
  if (process.env.NODE_ENV === 'production') return 'https://app.clickorder.co'
  if (process.env.NODE_ENV !== 'production') return 'http://localhost:8081'
}

function getMerchantUri() {
  if (process.env.NODE_ENV === 'production')
    return 'https://merchant.clickorder.co'
  if (process.env.NODE_ENV !== 'production') return 'http://localhost:8082'
}

export const isProd = () => process.env.NODE_ENV === 'production'
