<template>
  <div class="border-b-2 border-gray-200 mb-6">
    <h2 class="mb-3 font-bold text-2xl">{{ $t('my-order') }}</h2>
    <div class="flex justify-between items-end mb-3">
      <div>
        <span class="font-bold text-lg">
          {{ $t('total-price') }}
          <span :class="{ 'line-through text-red-500': hasDiscount }">
            {{ totalCost }}
          </span>
          <span class="pl-1" v-if="hasDiscount">
            {{ totalCost - discount.discount }}
          </span>
          {{ currency | currencify }}
        </span>

        <p class="text-sm">{{ $t('items') }}: {{ numOfCartItems }}</p>
      </div>

      <div class="text-sm" v-if="position">
        {{ position }}
      </div>
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: 'TotalCost2',

  props: {
    discount: {
      type: Object,
      required: true,
    },
    hasDiscount: {
      type: Boolean,
      required: true,
    },
    totalCost: {
      type: Number,
      required: true,
    },
    numOfCartItems: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: false,
    },
  },
}
</script>
