<template>
  <ul class="flex flex-col">
    <li
      v-for="categoryItem in categories"
      :key="categoryItem.id"
      class="p-2 rounded-md capitalize text-gray-600 hover:bg-gray-50 hover:text-gray-900"
      @click="onClick"
    >
      <a :href="`#${categoryItem.id}`">
        {{ $translate(categoryItem.id, categoryItem.title).value }}</a
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CategoryList',
  props: {
    onClick: {
      type: Function,
      required: true,
    },
  },
  computed: {
    categories() {
      return this.$store.getters.getCategories
    },
  },
}
</script>
