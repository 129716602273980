function currencify(value) {
  switch (value) {
    case 'eur':
      return '€'
    case 'usd':
      return '$'
    default:
      return ''
  }
}

function toLocalDate(value) {
  if (!value) return ''
  return new Date(value).toLocaleString()
}

module.exports = {
  currencify,
  toLocalDate,
}
