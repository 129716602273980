<template>
  <button class="p-2 border-1" @click="onClick">
    <slot>Button</slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    onClick: {
      type: Function,
      required: true,
    },
  },
}
</script>
