import {
  LIST_LOCALIZATIONS_REQUEST,
  LIST_LOCALIZATIONS_RESPONSE,
  LOCALIZATIONS_ERROR,
} from '../actions/localizations'
import { apiCall, api_routes } from '@/utils/api'
import myApp from '../../main'

const state = {
  localizations: {},
  status: '',
}

const getters = {
  getLocalization: state => lang => state.localizations[lang],
  getLocalizations: state => state.localizations,
  hasLocalizations: state => Object.keys(state.localizations).length > 0,
}

const actions = {
  [LIST_LOCALIZATIONS_REQUEST]: ({ commit }, storeId) => {
    return new Promise((resolve, reject) => {
      commit(LIST_LOCALIZATIONS_REQUEST)
      apiCall({
        url: `${api_routes.localizations}`,
        params: { store_id: storeId },
      })
        .then(resp => {
          commit(LIST_LOCALIZATIONS_RESPONSE, resp)

          myApp.$loadData()
          resolve(resp)
        })
        .catch(err => {
          commit(LOCALIZATIONS_ERROR, err)
          reject(err)
        })
    })
  },
}

const mutations = {
  [LIST_LOCALIZATIONS_REQUEST]: state => {
    state.status = 'loading localizations'
  },
  [LIST_LOCALIZATIONS_RESPONSE]: (state, resp) => {
    state.status = 'loaded localizations'
    state.localizations = resp.localizations
  },

  [LOCALIZATIONS_ERROR]: state => {
    state.status = 'error'
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
