export const ADD_TO_CART_REQUEST = 'add_to_basket_request'
export const ADD_TO_CART_RESPONSE = 'add_to_basket_response'

export const GET_CART_REQUEST = 'get_cart_request'
export const GET_CART_RESPONSE = 'get_cart_response'

export const REMOVE_CART_ITEM_REQUEST = 'remove_cart_item_request'
export const REMOVE_CART_ITEM_RESPONSE = 'remove_cart_item_response'
export const CLEAR_CART_REQUEST = 'clear_cart_request'

export const CART_ERROR = 'cart_error'
