import { settings } from '@/settings'
const state = {
  appName: settings.APP_NAME,
  domain: settings.DOMAIN,
}

const getters = {
  appName: state => state.appName,
  domain: state => state.domain,
}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
