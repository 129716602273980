<template>
  <div
    class="min-h-screen p-3"
    :class="{ 'bg-green-100': paymentSuccess, 'bg-red-200': !paymentSuccess }"
  >
    <QrScanner ref="qrScanner" :shouldRedirect="true" />
    <div
      class="mx-auto max-w-4xl px-6 py-8 md:p-6 lg:p-8 shadow-md rounded bg-white"
    >
      <div class="flex items-center mb-3">
        <CheckMarkIcon v-if="paymentSuccess" />
        <WarningCircleIcon v-if="!paymentSuccess" class="h-10 mr-3" />
        <h3 class="text-xl">
          {{ message }}
        </h3>
      </div>

      <order-summary v-if="paymentSuccess" class="" :order="order" />

      <div
        class="text-center font-semibold text-2xl p-2"
        v-if="!paymentSuccess"
      >
        {{ $t('try-again-by-scanning-the-qr') }}
      </div>
      <PreFooter>
        <button
          class="m-auto px-4 py-2 md:px-6 md:py-3 text-sm font-bold uppercase rounded shadow border border-gray-300 hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 text-white bg-gray-700 active:bg-blueGray-600 hover:bg-gray-600"
          @click="$refs.qrScanner.openCamera()"
        >
          {{ $t('re-scan-to-make-a-new-order') }}
        </button>
      </PreFooter>
    </div>
  </div>
</template>

<script>
import OrderSummary from '@/components/OrderSummary.vue'
import PreFooter from '@/components/PreFooter.vue'
import QrScanner from '@/components/QrScanner.vue'
import { ORDER_PAYMENT_REQUEST } from '../../store/actions/order'
import constants from '../../constants'
import { PaymentRequest } from '../../utils/requests'
import CheckMarkIcon from '../../assets/general/checkmark-circle.vue'
import WarningCircleIcon from '../../assets/general/warning-circle.vue'

export default {
  name: 'stripe-order-payment',

  components: {
    OrderSummary,
    CheckMarkIcon,
    WarningCircleIcon,
    QrScanner,
    PreFooter,
  },

  created() {
    const orderId = this.$route.params.id
    let paymentIntentId = this.$route.query.payment_intent

    const payload = new PaymentRequest(
      orderId,
      paymentIntentId,
      constants.STRIPE,
      this.$route.query.redirect_status
    )
    this.$store.dispatch(ORDER_PAYMENT_REQUEST, payload)
  },

  computed: {
    order() {
      return this.$store.getters.getOrder
    },

    paymentSuccess() {
      return this.$route.name === 'stripe-order-success'
    },

    message() {
      return this.paymentSuccess
        ? this.$i18n.t('success')
        : this.$i18n.t('ooops')
    },
  },
}
</script>
