<template>
  <span v-if="!enabled">
    <slot />
  </span>

  <router-link v-else :to="to">
    <slot />
  </router-link>
</template>

<script lang="js">
export default {
  name: 'ActiveRouterLink',

  props: {
    enabled: Boolean,
    to: String,
  },
}
</script>
