<template>
  <div>
    <label for="search-products" class="sr-only">Search items</label>
    <div class="relative rounded-md shadow-sm">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3">
        <SearchIcon />
      </div>
      <input
        type="text"
        v-model="searchTerm"
        name="search-products"
        id="search-products"
        class="block w-full rounded-md border-0 py-1.5 pl-10 pr-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-300 sm:text-sm sm:leading-6"
        :placeholder="$t('search')"
      />
    </div>
  </div>
</template>

<script>
import SearchIcon from '../assets/general/search.vue'

export default {
  data() {
    return {
      searchTerm: '',
    }
  },
  components: {
    SearchIcon,
  },
  watch: {
    searchTerm(newTerm) {
      this.$emit('input', newTerm)
    },
  },
}
</script>
