import { apiCall, api_routes } from '@/utils/api'
import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_RESPONSE,
  CART_ERROR,
  GET_CART_REQUEST,
  GET_CART_RESPONSE,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_RESPONSE,
  CLEAR_CART_REQUEST,
} from '../actions/cart'

const defaultCart = {
  id: '',
  position: '',
  store_id: '',
  items: [],
}

const state = {
  status: '',
  cart: defaultCart,
  message: '',
}

const getters = {
  getCartMessage: state => state.message,
  getCartId: state => state.cart?.id,
  getCart: state => state.cart,
  isCartEmpty: state =>
    state.cart?.items === undefined || state.cart?.items?.length === 0,
}

const actions = {
  [ADD_TO_CART_REQUEST]: ({ commit }, item) => {
    return new Promise((resolve, reject) => {
      commit(ADD_TO_CART_REQUEST)
      apiCall({ url: `${api_routes.cart}/`, method: 'POST', data: item })
        .then(resp => {
          commit(ADD_TO_CART_RESPONSE, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(CART_ERROR, err)
          reject(err)
        })
    })
  },

  [GET_CART_REQUEST]: ({ commit }, cartId) => {
    return new Promise((resolve, reject) => {
      commit(GET_CART_REQUEST)
      apiCall({ url: `${api_routes.cart}/${cartId}` })
        .then(resp => {
          commit(GET_CART_RESPONSE, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(CART_ERROR, err)
          reject(err)
        })
    })
  },
  [REMOVE_CART_ITEM_REQUEST]: ({ commit }, { cartId, cartItemId }) => {
    return new Promise((resolve, reject) => {
      commit(REMOVE_CART_ITEM_REQUEST)
      apiCall({
        url: `${api_routes.cart}/${cartId}/items/${cartItemId}`,
        method: 'DELETE',
      })
        .then(resp => {
          commit(REMOVE_CART_ITEM_RESPONSE, cartItemId)
          resolve(resp)
        })
        .catch(err => {
          commit(CART_ERROR, err)
          reject(err)
        })
    })
  },
  [CLEAR_CART_REQUEST]: ({ commit }) => {
    commit(CLEAR_CART_REQUEST)
  },
}

const mutations = {
  [ADD_TO_CART_REQUEST]: state => {
    state.status = 'adding to cart'
  },
  [ADD_TO_CART_RESPONSE]: (state, response) => {
    state.status = 'added to cart'
    state.cart = {
      id: response.cart_id,
      position: response.position,
      store_id: response.store_id,
      items: [...state.cart?.items, response.item],
    }
  },

  [GET_CART_REQUEST]: state => {
    state.status = 'loading cart'
  },
  [GET_CART_RESPONSE]: (state, response) => {
    state.status = 'cart loaded'
    state.cart = response
  },

  [REMOVE_CART_ITEM_REQUEST]: state => {
    state.status = 'removing from cart'
  },
  [REMOVE_CART_ITEM_RESPONSE]: (state, cartItemId) => {
    state.status = 'removed item from cart'
    state.cart = {
      ...state.cart,
      items: state.cart?.items?.filter(item => item.id !== cartItemId),
    }
  },

  [CLEAR_CART_REQUEST]: state => {
    state.status = 'clearing cart'
    state.cart = defaultCart
  },

  [CART_ERROR]: (state, error) => {
    state.status = 'error'
    if (error.response?.data?.msg === 'cart expired') {
      state.cart = defaultCart
      state.message = 'Your cart expired. Start over!'
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
