<template>
  <div class="mb-7" :id="id">
    <div class="capitalize font-bold text-lg border-b-2 mb-5">
      {{ name }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Category',
  props: ['name', 'id'],
}
</script>
