<template>
  <button
    class="active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 md:px-6 md:py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
    type="button"
    v-on="$listeners"
  >
    <div
      v-if="loading"
      class="flex items-center justify-center space-x-4 animate-spin"
    >
      <svg
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        style="margin: auto; background: transparent none repeat scroll 0% 0%;"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <path
          d="M13 50A37 37 0 0 0 87 50A37 41 0 0 1 13 50"
          fill="#ffffdd"
          stroke="none"
        ></path>
      </svg>
    </div>
    <span v-else>
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: 'LoadingButton',

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    startLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
    },
  },
}
</script>
