<template>
  <div class="p-2" :class="{ 'bg-red-100/40': notAllowedMore }">
    <div class="mb-2 text-lg font-bold">
      {{ $translate(option.id, option.title).value }}
    </div>
    <div
      class="flex items-start mb-2 justify-between"
      v-for="(c, idx) in option.customizations"
      :key="c.id"
    >
      <checkbox
        v-if="option.type === 'checkbox'"
        @input="select(option.type, option, c.id)"
        :id="c.id"
        :name="$translate(option.id, option.title).value"
        :value="c.id"
      >
        {{ $translate(c.id, c.title).value }}

        <div class="italic text-xs" v-if="c.description">
          {{ c.description }}
        </div>
      </checkbox>

      <radio
        v-if="option.type === 'radio'"
        @input="select(option.type, option, c.id)"
        :id="c.id"
        :name="$translate(option.id, option.title).value"
        :value="c.id"
        v-bind:checked="idx === 0"
      >
        {{ $translate(c.id, c.title).value }}

        <div class="italic text-xs" v-if="c.description">
          {{ c.description }}
        </div>
      </radio>

      <Price :price="c.price" :currency="item.currency" />
    </div>
  </div>
</template>

<script lang="js">
import Price from './Price.vue'

export default {

  components: {
    Price,
  },

  props: {
    option: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      checkedCustomizations: {},
      numSelected: 0,
    }
  },

  methods: {
    select(type, opt, id) {
      if (this.checkedCustomizations[opt.id] === undefined) {
        this.checkedCustomizations[opt.id] = []
      }
      if (type === 'radio') {
        if (!this.checkedCustomizations[opt.id].includes(id)) {
          this.checkedCustomizations[opt.id].splice(
            0,
            this.checkedCustomizations[opt.id].length
          )
          this.checkedCustomizations[opt.id].push(id)
          this.numSelected = this.checkedCustomizations[opt.id].length
        }
      }

      if (type === 'checkbox') {
        if (this.checkedCustomizations[opt.id].includes(id)) {
          // remove existing customization
          this.checkedCustomizations[opt.id] = this.checkedCustomizations[
            opt.id
          ].filter(item => item !== id)
        } else {
          // add non-existing customization
          this.checkedCustomizations[opt.id].push(id)
        }
        this.numSelected = this.checkedCustomizations[opt.id].length

        // this is needed only for checkboxes and it will not update the parent
        if (this.notAllowedMore) {
          return
        }
      }

      this.$emit('update:customizations', opt.id, this.checkedCustomizations)
    },
  },

  computed: {
    notAllowedMore() {
      return this.option.max_customizations > 0 && this.option.max_customizations < this.numSelected
    }
  },
}
</script>
