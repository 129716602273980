<template>
  <div class="relative inline-block text-left">
    <button
      type="button"
      class="inline-flex w-full justify-center gap-x-1.5 rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      id="menu-button"
      aria-expanded="true"
      aria-haspopup="true"
      @click="toggleMenu"
    >
      <component :is="selectedFlagIcon" class="w-5" />
      <ArrowDownIcon />
    </button>

    <div
      v-if="isMenuOpen"
      class="absolute z-10 mt-2 divide-y divide-gray-100 rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
      ref="menu"
      @click.self="closeMenu"
    >
      <button
        v-for="(locale, i) in locales"
        :key="`locale-${i}`"
        class="text-gray-700 group flex items-center px-4 py-2 text-sm"
        role="menuitem"
        tabindex="-1"
        :id="`menu-item-${i}`"
        @click="changeLocale(locale)"
      >
        <component :is="localeFlagIcon(locale)" class="w-5" />
      </button>
    </div>
  </div>
</template>

<script>
import ukFlagIcon from '../assets/general/flag-uk.vue'
import grFlagIcon from '../assets/general/flag-gr.vue'
import ArrowDownIcon from '../assets/general/arrow-down.vue'

export default {
  name: 'LocaleSwitcher',
  components: {
    ukFlagIcon,
    grFlagIcon,
    ArrowDownIcon,
  },

  data() {
    return {
      locales: ['el_GR', 'en_US'],
      isMenuOpen: false,
    }
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    // changeLocale(locale) {
    //   this.selectedLocale = locale
    //   this.$i18n.locale = locale
    //   this.isMenuOpen = false
    // },

    changeLocale(locale) {
      const selectedLocale = locale === 'el_GR' ? 'el_GR' : 'en_US'
      if (this.$i18n.locale !== selectedLocale) {
        this.$i18n.locale = selectedLocale
      }
      this.isMenuOpen = false
    },

    // closeMenu() {
    //   this.isMenuOpen = false
    // },

    closeMenu(event) {
      if (!this.$el.contains(event.target)) {
        this.isMenuOpen = false
      }
    },
  },
  computed: {
    selectedLocale: {
      get() {
        return this.$i18n.locale === 'el_GR' ? 'el_GR' : 'en_US'
      },
      set(locale) {
        this.$i18n.locale = locale
      },
    },
    localeFlagIcon() {
      return locale => {
        if (locale === 'el_GR') {
          return grFlagIcon
        } else if (locale === 'en_US') {
          return ukFlagIcon
        }
      }
    },
    selectedFlagIcon() {
      return this.localeFlagIcon(this.selectedLocale)
    },
  },
  mounted() {
    document.addEventListener('click', this.closeMenu)
  },

  beforeUnmount() {
    document.removeEventListener('click', this.closeMenu)
  },
}
</script>
