import {
  INIT_ERROR,
  SAVE_STORE_TABLE_INFO,
  FETCH_STORE_REQUEST,
  FETCH_STORE_RESPONSE,
} from '../actions/init'
import { apiCall, api_routes } from '@/utils/api'
import { VIVA_WALLET } from '../../constants'

const state = {
  status: '',
  token: '',
  store: {},
  position: '',
  area_short_id: '',
  payment_provider: VIVA_WALLET,
}

const getters = {
  getStore: state => state.store,

  getPosition: state =>
    state.position || JSON.parse(sessionStorage.getItem('info'))?.positionId,

  getPositions: state => state.store.positions,

  getArea: state =>
    state.area_short_id ||
    JSON.parse(sessionStorage.getItem('info'))?.area_short_id,

  getPaymentProvider: state => state.store.payment_provider,
}

const actions = {
  [FETCH_STORE_REQUEST]: ({ commit }, storeId) => {
    return new Promise((resolve, reject) => {
      commit(FETCH_STORE_REQUEST)
      apiCall({ url: `${api_routes.store}/${storeId}` })
        .then(resp => {
          commit(FETCH_STORE_RESPONSE, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(INIT_ERROR, err)
          reject(err)
        })
    })
  },

  [SAVE_STORE_TABLE_INFO]: ({ commit }, data) => {
    return new Promise(resolve => {
      commit(SAVE_STORE_TABLE_INFO, data)
      sessionStorage.setItem('info', JSON.stringify(data))
      resolve(data)
    })
  },
}

const mutations = {
  [FETCH_STORE_REQUEST]: state => {
    state.status = 'fetching store data'
  },
  [FETCH_STORE_RESPONSE]: (state, resp) => {
    state.status = 'fetch store data'
    state.store = resp
  },

  [SAVE_STORE_TABLE_INFO]: (state, data) => {
    state.status = 'saved table info'
    state.store = { ...state.store, id: data.storeId }
    state.position = data.positionId
    state.area_short_id = data.area_short_id
  },

  [INIT_ERROR]: state => {
    state.status = 'error'
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
