import {
  LOAD_CONTENT_REQUEST,
  LOAD_CONTENT_RESPONSE,
  CMS_ERROR,
  SUBMIT_CONTACT_INFO_REQUEST,
  SUBMIT_CONTACT_INFO_RESPONSE,
} from '../actions/cms'
import { apiCall, api_routes } from '@/utils/api'

const state = {
  status: '',
  cms: {},
}

const getters = {
  getHome: state => state.cms.home,
}

const actions = {
  [LOAD_CONTENT_REQUEST]: ({ commit }, locale) => {
    return new Promise((resolve, reject) => {
      commit(LOAD_CONTENT_REQUEST)
      apiCall({ url: `${api_routes.cms}`, params: { lang: locale } })
        .then(resp => {
          commit(LOAD_CONTENT_RESPONSE, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(CMS_ERROR, err)
          reject(err)
        })
    })
  },

  [SUBMIT_CONTACT_INFO_REQUEST]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit(SUBMIT_CONTACT_INFO_REQUEST)
      apiCall({ url: `${api_routes.cms}/contact-us`, method: 'POST', data })
        .then(resp => {
          commit(SUBMIT_CONTACT_INFO_RESPONSE, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(CMS_ERROR, err)
          reject(err)
        })
    })
  },
}

const mutations = {
  [LOAD_CONTENT_REQUEST]: state => {
    state.status = 'loading content'
  },
  [LOAD_CONTENT_RESPONSE]: (state, resp) => {
    state.status = 'loaded content'
    state.cms = resp
  },

  [SUBMIT_CONTACT_INFO_REQUEST]: state => {
    state.status = 'submitting contact info'
  },
  [SUBMIT_CONTACT_INFO_RESPONSE]: state => {
    state.status = 'submitted contact info'
  },

  [CMS_ERROR]: state => {
    state.status = 'error'
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
