<template>
  <div
    class="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 py-2.5 px-6 sm:rounded-xl sm:py-3 sm:pr-3.5 sm:pl-4 w-full md:w-fit z-50"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BannerFloating',
}
</script>
