<template>
  <div
    v-show="showScanner"
    class="fixed top-12 inset-x-0 mx-auto h-5/6 max-w-4xl p-4 lg:p-5 bg-white/[.80]"
    @click="closeScanner"
  >
    <button
      @click="closeScanner"
      class="absolute right-2 top-2 z-10 border border-black rounded-full"
    >
      <CloseIcon class="bg-white rounded-full" />
    </button>
    <div v-if="showScanner">
      <qrcode-stream @decode="onDecode" :track="trackQRCode" />
    </div>
  </div>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import CloseIcon from '../assets/general/close.vue'

export default {
  components: {
    QrcodeStream,
    CloseIcon,
  },
  props: {
    shouldRedirect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: '',
      showScanner: false,
      decodedString: '',
    }
  },
  methods: {
    async openCamera() {
      if (this.showScanner) {
        return
      }

      try {
        await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' },
        })
        this.showScanner = true
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'user denied camera access permission'
        } else if (error.name === 'NotFoundError') {
          this.error = 'no suitable camera device installed'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'page is not served over HTTPS (or localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'maybe camera is already in use'
        } else if (error.name === 'OverconstrainedError') {
          this.error =
            'did you request the front camera although there is none?'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'browser seems to be lacking features'
        }
      }
    },

    onDecode(decodedString) {
      this.decodedString = decodedString
      this.$emit('decoded', decodedString)

      setTimeout(() => {
        this.closeScanner()
        if (this.shouldRedirect) {
          window.location.replace(decodedString)
        }
      }, 2000) // wait for 2 seconds before redirecting
    },

    trackQRCode(detectedCodes, ctx) {
      ctx.strokeStyle = 'red'
      ctx.lineWidth = 3
      if (detectedCodes && detectedCodes.length > 0) {
        detectedCodes.forEach(detectedCode => {
          const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

          ctx.beginPath()
          ctx.moveTo(firstPoint.x, firstPoint.y)

          otherPoints.forEach(({ x, y }) => {
            ctx.lineTo(x, y)
          })

          ctx.closePath()
          ctx.strokeRect(
            firstPoint.x,
            firstPoint.y,
            otherPoints[0].x - firstPoint.x,
            otherPoints[2].y - firstPoint.y
          )
        })
      }
    },
    closeScanner() {
      this.showScanner = false
      this.decodedString = ''
    },
  },
}
</script>
