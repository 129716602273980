// Usage: import DynamicTranslator from '@/utils/translator'
//        Vue.use(DynamicTranslator)
//        this.$translate('key', 'fallback').value
//
//                        OR
//
//        {{ $translate('key', 'fallback').value }}
//        this.$loadData()
//
export const LOADED_TRANSLATIONS = 'LOADED_TRANSLATIONS'

const localeDetector = {
  'el-GR': 'el_GR',
  'en-US': 'en_US',
  el: 'el_GR',
  gr: 'el_GR',
  en: 'en_US',
  el_GR: 'el_GR',
  en_US: 'en_US',
}

export const detectLocale = locale => {
  return localeDetector[locale] || locale
}

const createFallback = fallback => {
  return { value: fallback, value2: fallback }
}

const DynamicTranslator = {
  install(Vue) {
    let translations = {}
    const defaultLocale = 'el_GR'

    Vue.prototype.$translate = function(key, fallback) {
      const locale = detectLocale(this.$i18n.locale || defaultLocale)
      const trs = translations[locale]?.find(t => t.reference_id === key)
      if (trs === undefined) {
        return createFallback(fallback)
      }
      return trs
    }

    Vue.prototype.$loadData = function() {
      this.$emit(LOADED_TRANSLATIONS)
    }

    Vue.mixin({
      created() {
        this.$on(LOADED_TRANSLATIONS, function() {
          translations = this.$store.getters.getLocalizations
        })
      },
    })
  },
}

export default DynamicTranslator
