<template>
  <div>
    <h1 class="text-2xl font-bold text-center py-2">{{ $t('hhhhmm') }}</h1>
    <div class="text-lg font-bold text-center py-2">{{ cartMessage }}</div>
    <div class="text-center py-2">
      {{ $t('go') }}
      <a class="underline cursor-pointer" @click="goBack">{{ $t('back') }}</a>
      {{ $t('and-add-something') }}
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: 'EmptyCart',

  props: {
    cartMessage: {
      type: String,
      required: true,
    },
  },

  methods: {
    goBack() {
      this.$router.back()
    },
  },
}
</script>
