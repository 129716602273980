<template>
  <div
    :class="{
      'line-through decoration-double decoration-2 decoration-red-200': !isEnabled,
    }"
  >
    <ActiveRouterLink :enabled="isEnabled" :to="detailsLink" class="flex">
      <div>
        <h2 class="font-bold">
          {{ $translate(item.id, item.title).value }}
        </h2>
        <div>
          {{ amountFromCents(item.price) }}
          {{ item.currency | currencify }}
        </div>
        <span></span>
      </div>

      <div class="flex flex-1 justify-end self-center">
        <div
          class="w-20 h-20"
          :class="{ 'bg-gray-200 rounded-md overflow-hidden': mainImage }"
        >
          <img
            v-if="mainImage"
            :src="mainImage"
            class="w-20 h-20 object-center object-cover"
          />
        </div>
      </div>
    </ActiveRouterLink>
  </div>
</template>

<script>
import { amountFromCents } from '../utils/utils'

export default {
  name: 'CatalogItemThumbnail',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    detailsLink() {
      const storeId = this.$route.params.id
      return `/store/${storeId}/item/${this.item.id}`
    },

    mainImage() {
      return this.item?.images[0]
    },
    isEnabled() {
      return this.item.enabled
    },
  },

  methods: {
    amountFromCents,
  },
}
</script>
