<template>
  <div
    class="fixed left-0 bottom-0 bg-white w-full border-t-2 border-gray-200 z-50"
  >
    <div
      class="flex justify-between items-start m-auto p-3 w-full max-w-4xl md:px-6 lg:px-8"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreFooter',
}
</script>
