import {
  LOAD_CATALOG_REQUEST,
  LOAD_CATALOG_RESPONSE,
  CATALOG_ERROR,
} from '../actions/catalog'
import { apiCall, api_routes } from '@/utils/api'

const state = {
  status: '',
  catalog: {},
  storeId: '',
}

const getters = {
  getCatalog: state => {
    return state.catalog
  },

  getCategories: state => {
    return state.catalog.categories
  },

  getItemsByCategory: state => id => {
    return state.catalog.products_by_category[id]
  },

  getItems: state => {
    const flat = []
    const products = state.catalog.products_by_category
    for (let key in products) {
      flat.push(...products[key])
    }
    return flat
  },

  getItem: state => id => {
    const products = state.catalog.products_by_category
    for (const key in products) {
      const tmp = products[key]
      let found = tmp.find(item => item.id === id)
      if (found) {
        return found
      }
    }
    return null
  },

  getStoreId: state => state.storeId,
}

const actions = {
  [LOAD_CATALOG_REQUEST]: ({ commit }, storeId) => {
    return new Promise((resolve, reject) => {
      commit(LOAD_CATALOG_REQUEST, storeId)
      apiCall({ url: `${api_routes.store}/${storeId}/catalogs` })
        .then(resp => {
          commit(LOAD_CATALOG_RESPONSE, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(CATALOG_ERROR, err)
          reject(err)
        })
    })
  },
}

const mutations = {
  [LOAD_CATALOG_REQUEST]: (state, storeId) => {
    state.status = `loading catalog ${storeId}`
    state.storeId = storeId
  },
  [LOAD_CATALOG_RESPONSE]: (state, response) => {
    state.status = 'catalog loaded'
    state.catalog = response
  },

  [CATALOG_ERROR]: state => {
    state.status = 'error'
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
