import { apiCall, api_routes } from '@/utils/api'
import {
  CARD_ERROR,
  GET_CARD_RESPONSE,
  GET_CARD_REQUEST,
  SAVE_CARD,
} from '../actions/card'

const state = {
  status: '',
  card: {},
}

const getters = {
  getCard: state => state.card,
}

const actions = {
  [SAVE_CARD]: ({ commit }, card_id) => {
    return new Promise(() => {
      commit(SAVE_CARD, card_id)
    })
  },

  [GET_CARD_REQUEST]: ({ commit }, { card_id, store_id }) => {
    return new Promise((resolve, reject) => {
      commit(GET_CARD_REQUEST)
      apiCall({
        url: `${api_routes.card}/${card_id}`,
        method: 'GET',
        params: {
          store_id,
        },
      })
        .then(resp => {
          commit(GET_CARD_RESPONSE, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(CARD_ERROR, err)
          reject(err)
        })
    })
  },
}

const mutations = {
  [SAVE_CARD]: (state, card_id) => {
    state.status = 'saving card'
    state.card = { id: card_id }
  },

  [GET_CARD_REQUEST]: state => {
    state.status = 'getting card'
  },
  [GET_CARD_RESPONSE]: (state, resp) => {
    state.status = 'got card'
    state.card = resp
  },

  [CARD_ERROR]: state => {
    state.status = 'error'
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
