import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/views/layouts/Layout.vue'
import Catalog from '@/views/pages/Catalog.vue'
import CatalogItem from '@/views/pages/CatalogItem.vue'
import Store from '@/views/pages/Store.vue'
import Cart from '@/views/pages/Cart.vue'
import StripeOrderPayment from '@/views/pages/StripeOrderPayment.vue'
import CashOrderPayment from '@/views/pages/CashOrderPayment.vue'
import VivaOrderPayment from '@/views/pages/VivaOrderPayment.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Layout',
      component: Layout,
      children: [
        {
          path: '/store/:id',
          name: 'store',
          component: Store,
        },
        {
          path: '/store/:id/catalog',
          name: 'catalog',
          component: Catalog,
        },
        {
          path: '/store/:id/item/:item_id',
          name: 'item-details',
          component: CatalogItem,
        },
        {
          path: '/cart/:id',
          name: 'cart',
          component: Cart,
        },

        // Stripe payment confirmation
        {
          path: '/order/:id/success',
          name: 'stripe-order-success',
          component: StripeOrderPayment,
        },
        {
          path: '/order/:id/failure',
          name: 'stripe-order-failure',
          component: StripeOrderPayment,
        },

        // Cash
        {
          path: '/order/:id/cash',
          name: 'cash-order-success',
          component: CashOrderPayment,
        },

        {
          path: '/order/success',
          name: 'viva-order-success',
          component: VivaOrderPayment,
        },
        {
          path: '/order/failure',
          name: 'viva-order-failure',
          component: VivaOrderPayment,
        },
      ],
    },
  ],
})
