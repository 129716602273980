import { apiCall, api_routes } from '@/utils/api'
import {
  ORDER_CHECKOUT_REQUEST,
  ORDER_CHECKOUT_RESPONSE,
  ORDER_ERROR,
  ORDER_PAYMENT_REQUEST,
  ORDER_PAYMENT_RESPONSE,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_RESPONSE,
} from '../actions/order'
import { CLEAR_CART_REQUEST } from '../actions/cart'

const state = {
  status: '',
  order: {},
  stripeElementOptions: {
    appearance: {
      theme: 'stripe',
    },
    clientSecret: '',
  },
}

const getters = {
  getOrder: state => state.order,
  getElementOptions: state => state.stripeElementOptions,
}

const actions = {
  [UPDATE_ORDER_REQUEST]: ({ commit }, order) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_ORDER_REQUEST)
      apiCall({
        url: `${api_routes.order}/${order.id}`,
        method: 'PUT',
        data: { order },
      })
        .then(resp => {
          commit(UPDATE_ORDER_RESPONSE, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(ORDER_ERROR, err)
          reject(err)
        })
    })
  },

  [ORDER_CHECKOUT_REQUEST]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit(ORDER_CHECKOUT_REQUEST)
      apiCall({ url: `${api_routes.order}/`, method: 'POST', data })
        .then(resp => {
          commit(ORDER_CHECKOUT_RESPONSE, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(ORDER_ERROR, err)
          reject(err)
        })
    })
  },

  [ORDER_PAYMENT_REQUEST]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit(ORDER_PAYMENT_REQUEST)
      // NOTE: data.external_payment_id handles Viva Wallet case
      const orderId = data.id || data.external_payment_id
      apiCall({
        url: `${api_routes.order}/${orderId}/pay`,
        method: 'POST',
        data,
      })
        .then(resp => {
          commit(ORDER_PAYMENT_RESPONSE, resp)
          dispatch(CLEAR_CART_REQUEST)
          resolve(resp)
        })
        .catch(err => {
          commit(ORDER_ERROR, err)
          reject(err)
        })
    })
  },
}

const mutations = {
  [ORDER_CHECKOUT_REQUEST]: state => {
    state.status = 'creating draft order'
  },
  [ORDER_CHECKOUT_RESPONSE]: (state, response) => {
    state.status = 'draft order created'
    state.order = response
    state.stripeElementOptions.clientSecret = response.external_order_code
  },

  [ORDER_PAYMENT_REQUEST]: state => {
    state.status = 'stripe order update'
  },
  [ORDER_PAYMENT_RESPONSE]: (state, response) => {
    state.status = 'stripe order completed'
    state.order = response
  },

  [UPDATE_ORDER_REQUEST]: state => {
    state.status = 'updating order'
  },
  [UPDATE_ORDER_RESPONSE]: (state, order) => {
    state.status = 'order updated'
    state.order = order
  },

  [ORDER_ERROR]: state => {
    state.status = 'order error'
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
