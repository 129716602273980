<template>
  <router-link
    :class="numOfCartItems == 0 ? 'cart cursor-not-allowed' : ''"
    v-if="isCartAvailable"
    :is="activeCardLink ? 'span' : 'router-link'"
    :to="cartPath"
  >
    <div class="flex">
      <div
        :class="activeCardLink ? 'bg-gray-300' : 'bg-black'"
        class="text-white h-6 w-6 rounded-full text-center"
      >
        {{ numOfCartItems }}
      </div>
      <ShoppingCartIcon />
    </div>
  </router-link>
</template>

<script>
import ShoppingCartIcon from '../assets/general/shopping-cart'

export default {
  components: { ShoppingCartIcon },
  name: 'CartButton',
  props: ['isCartAvailable', 'cartId'],
  computed: {
    cartPath() {
      return `/cart/${this.cartId}`
    },
    activeCardLink() {
      return this.$store.getters.getCart?.items?.length === 0 ? true : false
    },
    numOfCartItems() {
      return this.$store.getters.getCart?.items?.length || 0
    },
  },
}
</script>
