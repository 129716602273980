<template>
  <div class="flex items-center" :class="[containerClasses, heroSize]">
    <img
      v-if="heroImage"
      class="absolute w-full object-cover -z-10"
      :class="heroSize"
      :src="heroImage"
      :alt="altText"
    />
    <div
      v-if="heroImage"
      class="absolute w-full bg-black opacity-20 -z-10"
      :class="heroSize"
    />
    <div class="lg:max-w-7xl w-full">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heroImage: {
      type: String,
    },
    altText: {
      type: String,
      default: 'Hero Image',
    },
    justifyContent: {
      type: String,
      default: 'center',
    },
    size: {
      type: String,
      default: 'small',
      validator: function(value) {
        return ['small', 'big'].includes(value)
      },
    },
  },
  computed: {
    containerClasses() {
      return [`justify-${this.justifyContent}`]
    },
    heroSize() {
      return this.size === 'small' ? 'h-36 md:h-52' : 'h-52 md:h-64'
    },
  },
}
</script>
